import c1 from "../../../assets/g1.jpg";
import c2 from "../../../assets/g7.webp";
import c3 from "../../../assets/g26.jpg";
import c4 from "../../../assets/g23.jpg";
import c5 from "../../../assets/g21.jpg";
import c6 from "../../../assets/g29.webp";
import c7 from "../../../assets/g28.jpg";
import c8 from "../../../assets/g8.webp";
import c9 from "../../../assets/g22.webp";
import c10 from "../../../assets/g24.jpg";
import c11 from "../../../assets/g18.jpeg";
import c12 from "../../../assets/g16.jpg";
import c13 from "../../../assets/g25.jpg";
import c14 from "../../../assets/g27.webp";

const data = [
  { css: c1, height: 500 },
  { css: c2, height: 500 },
  { css: c3, height: 500 },
  { css: c4, height: 500 }, 
  { css: c5, height: 500 },
  { css: c6, height: 500 },
  { css: c7, height: 400 },
  { css: c8, height: 500 },
  { css: c9, height: 400 },
  { css: c10, height: 400 },
  { css: c11, height: 400 },
  { css: c12, height: 500 },
  { css: c13, height: 400 },
  { css: c14, height: 400 },
  ];
  // { css: /416430/416430, height: 300 },
  // { css: /1103970/1103970, height: 300 },
  // { css: /911738/911738, height: 300 },
  // { css: /358574/358574, height: 300 },
  // { css: /1738986/1738986, height: 300 },
  // { css: /96381/96381, height: 300 },
  // { css: /1005644/1005644, height: 200 },
  // { css: /227675/227675, height: 300 },
  // { css: /325185/325185, height: 200 },
  // { css: /327482/327482, height: 400 },
  // { css: /2736834/2736834, height: 200 },
  // { css: /249074/249074, height: 300 },
  // { css: /310452/310452, height: 400 },
  // { css: /380337/380337, height: 200 },
  // ];
  
  export default data;
  