import React from 'react';

import './ServiceCard.css'
import { Link } from 'react-router-dom';
import Scard from './Scard';

const ServiceCard = () => {
  return (
    <>
      
  <Scard/>

    
      
    </>
  );
};

export default ServiceCard;
